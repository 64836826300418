.input-plus-wrraper {
    display: flex;

    &.isSelected {
        .input-area {
            width: 100%;
        }
    }

    .input-area {
        width: calc(100% - 40px);
    }

    .icon-area {
        width: 32px;
        height: 32px;
        margin-left: 3px;
        background-color: $light;
        // color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;

        .icon {
            display: flex;
            font-size: 20px;
        }
    }
}