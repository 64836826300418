.input-wrapper-icon {
	position: relative;

	.icon-hodlder {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		font-size: 20px;
		width: 35px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
}
