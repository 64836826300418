.select-error__control {
    border-color: $danger  !important;
}

.react-select-custom {
    .select__control {
        border-radius: 16px !important;
    }

    &.select-error {
        .select__control {
            border-color: $danger  !important;
        }
    }
}