.show-vehicle-img {
	.img-holder2,
	.img-holder {
		width: 100%;
		object-fit: cover;
		cursor: pointer;
		border: 1px solid $border-color;
		border-radius: 5px;
		margin-bottom: 5px;
	}

	.img-holder {
		height: 150px;
	}

	.flag {
		height: 45px;
		width: 80px;
	}

	.img-holder2 {
		height: 200px;
	}
}

.vehicle-img-details {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.single {
		padding: 3px;
	}

	img {
		width: 100%;
		max-width: 220px;
		height: 180px;
		border: 1px solid $border-color;
		border-radius: 5px;
		object-fit: cover;
		cursor: pointer;
	}
}
