.auction-container {
    position: relative;

    .box-container {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;


        &.box-c-info {
            .dddd {
                background-color: $info;
            }

            .bid-place-wrapper {
                background-color: $info;
            }
        }

        &.box-c-primary {
            .bid-place-wrapper {
                background-color: $primary;
            }
        }

        &.box-c-danger {
            .bid-place-wrapper {
                background-color: $danger;
            }
        }

        // &.active {
        //     // background-color: transparent;
        //     .bid-place-wrapper {
        //         background-color: transparent;
        //     }
        // }

        .flag-img {
            max-width: 50px;
        }

    }

    .bid-info-wrraper {
        width: 100%;
        height: 100%;
        // background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .country-info {
            font-size: 18px;
            text-align: center;
            line-height: 1.3;
            margin-bottom: 8px;
            padding-top: 10px;
        }
    }

    .new-bid-wrapper {
        width: 100%;
        height: 100%;


    }

    .bid-place-text {
        font-size: 18px;
        color: $white;
    }

    .bid-place-wrapper {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

}



.auction-top {
    margin-top: 15px;
    margin-bottom: 15px;


    @include media-breakpoint-up(sm) {
        padding-left: 10px;
        padding-right: 10px;
    }
}



.auction-container-custom {
    display: flex;
    flex-wrap: wrap;

    .row-auction {
        width: 100%;

        @include media-breakpoint-up(xl) {
            width: 75%;
        }

        @include media-breakpoint-up(xxl) {
            width: 50%;
        }

        .col-auction {
            width: 100%;

            @include media-breakpoint-up(sm) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .col-gallery {
            width: 300px;

            @include media-breakpoint-up(lg) {
                width: 500px;
            }


        }

        &:has(.col-gallery) {
            width: 100%;
            display: flex;

            .col-auction {
                width: 100%;

                // @include media-breakpoint-up(lg) {
                //     width: 75%;
                // }
                @include media-breakpoint-up(lg) {
                    width: 75%;
                }

                @include media-breakpoint-up(xxl) {
                    width: 50%;
                }

            }
        }
    }


}

.gallery-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($primary, .3);
}


.bell-icons {
    position: absolute;
    right: 20px;
    top: 20px;
}