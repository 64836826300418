.auto-complete-options {
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;

    &.active {
        background-color: $blue;
        color: $white;
    }
}


[theme=dark] .auto-complete-options {
    background-color: $dark;

    &.active {
        background-color: $blue;
        color: $white;
    }
}